<template>
    <v-container fluid ref="container" class="pb-0">
        <v-row no-gutters ref="details">
            <v-col cols="4">
                <div>
                    <p>
                        <strong>ID: </strong>
                        {{ workOrder.code.split('-')[1] }}
                    </p>

                    <p>
                        <strong>CREATION DATE: </strong
                        >{{
                            ` ${
                                workOrder.createdOn
                                    ? formatDate(
                                          workOrder.createdOn.seconds ||
                                              workOrder.createdOn._seconds
                                      )
                                    : ''
                            }`
                        }}
                    </p>
                    <p
                        v-if="
                            workOrder.productionEnd &&
                                (workOrder.status == 'finished' ||
                                    workOrder.status == 'closed')
                        "
                    >
                        <strong>PRODUCTION END: </strong
                        >{{
                            ` ${
                                workorder.productionEnd
                                    ? formatDate(
                                          workOrder.productionEnd.seconds ||
                                              workOrder.productionEnd._seconds
                                      )
                                    : ''
                            }`
                        }}
                    </p>
                    <p class="text-capitalize">
                        <strong>CREATED BY: </strong>
                        {{
                            ` ${
                                workOrder.createdBy && workOrder.createdBy.name
                                    ? workOrder.createdBy.name
                                    : workOrder.createdBy
                            }`
                        }}
                    </p>
                    <p class="text-capitalize">
                        <strong>PROJECT MANAGER:</strong
                        >{{ ` ${projectManagerName}` }}
                    </p>
                    <div class="d-flex">
                        <div class="d-flex align-center">
                            <strong>ATTACHED: </strong>
                        </div>
                        <div class="d-flex align-center flex-wrap">
                            <v-btn
                                color="grey lighten-3"
                                elevation="0"
                                x-small
                                class="ma-1 text-capitalize grey--text text--darken-1  "
                                v-for="(file, i) in workOrder.attachments"
                                :key="i"
                            >
                                <span @click="openFileAttached(file)">{{
                                    file
                                }}</span>
                                <v-icon
                                    v-if="
                                        (workOrder.status == 'inProgress' ||
                                            workOrder.status == 'rejected') &&
                                            !readOnlyWO
                                    "
                                    @click="deleteFile(file)"
                                    class="ml-1"
                                    small
                                >
                                    mdi-close-circle
                                </v-icon>
                            </v-btn>
                            <v-btn
                                @click="openWorkOrderFiles"
                                depressed
                                fab
                                x-small
                                color="primary"
                                class="ml-xl-2 ml-lg-2 ml-md-2 ml-sm-2"
                                v-if="
                                    (workOrder.status == 'inProgress' ||
                                        workOrder.status == 'rejected') &&
                                        !readOnlyWO
                                "
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                class="pl-xl-2 pl-lg-2 pl-md-2"
            >
                <v-textarea
                    v-model="workOrder.notes"
                    prepend-icon="mdi-text"
                    prefix="Notes: "
                    hide-details
                    rows="1"
                    @blur="saveNotes"
                    :disabled="readOnlyWO"
                >
                </v-textarea>
            </v-col>
            <v-spacer />
            <v-col cols="4" class="d-flex justify-end">
                <canvas id="qr"></canvas>
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-8">
            <v-col cols="12">
                <v-data-table
                    id="virtual-scroll-table"
                    :headers="headers"
                    :items="filterWorkOrders"
                    class="elevation-0"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    :style="`height: ${height}px`"
                >
                    <template v-slot:top>
                        <v-row
                            no-gutter
                            style="background-color: #eeeeee"
                            class="mt-0 mx-0"
                        >
                            <v-col cols="4" class="d-flex">
                                <h2 class="mx-4 mt-1">ITEMS</h2>
                                <v-btn
                                    class="mt-1"
                                    small
                                    icon
                                    color="primary"
                                    @click="openDeleteAllItem"
                                    v-if="
                                        (workOrder.status == 'inProgress' ||
                                            workOrder.status == 'rejected') &&
                                            !readOnlyWO
                                    "
                                >
                                    <v-icon>mdi-delete-sweep-outline</v-icon>
                                </v-btn>
                            </v-col>
                            <v-spacer />
                            <v-col
                                cols="4"
                                class="d-flex justify-end"
                                v-if="
                                    (workOrder.status == 'inProgress' ||
                                        workOrder.status == 'rejected') &&
                                        !readOnlyWO
                                "
                            >
                                <v-btn
                                    rounded
                                    color="primary"
                                    class="mx-2"
                                    @click="openAddItem"
                                >
                                    <v-icon>
                                        mdi-plus
                                    </v-icon>
                                    {{
                                        $vuetify.breakpoint.smAndDown
                                            ? ''
                                            : 'NEW ITEM'
                                    }}
                                </v-btn>
                                <v-btn
                                    rounded
                                    color="primary"
                                    class="mx-2"
                                    @click="importFiles"
                                >
                                    <v-icon>
                                        mdi-login-variant
                                    </v-icon>
                                    {{
                                        $vuetify.breakpoint.smAndDown
                                            ? ''
                                            : 'IMPORT'
                                    }}
                                </v-btn>
                                <v-btn
                                    rounded
                                    color="primary"
                                    class="mx-2"
                                    @click="openUploadFiles"
                                >
                                    <v-icon>
                                        mdi-upload
                                    </v-icon>
                                    {{
                                        $vuetify.breakpoint.smAndDown
                                            ? ''
                                            : 'FILES'
                                    }}
                                </v-btn>
                            </v-col>
                            <v-col
                                cols="4"
                                class="d-flex justify-end"
                                v-if="
                                    !user.operatorRole &&
                                        workOrder.status == 'approved'
                                "
                            >
                                <div class="d-flex justify-center pr-3">
                                    <v-btn
                                        rounded
                                        color="primary"
                                        class="mx-2"
                                        @click="openMultipleProcesses"
                                        v-if="
                                            !user.operatorRole &&
                                                user.permissions.includes(
                                                    'updateWOProcesses'
                                                )
                                        "
                                    >
                                        <v-icon>
                                            mdi-swap-vertical
                                        </v-icon>
                                        {{
                                            $vuetify.breakpoint.smAndDown
                                                ? ''
                                                : 'SET PROCESSES'
                                        }}
                                    </v-btn>
                                    <v-btn
                                        rounded
                                        color="primary"
                                        class="mx-2"
                                        @click="openMultipleRegister"
                                    >
                                        <v-icon class="mr-1">
                                            mdi-tools
                                        </v-icon>
                                        {{
                                            $vuetify.breakpoint.smAndDown
                                                ? ''
                                                : 'REGISTER ITEMS'
                                        }}
                                    </v-btn>
                                    <v-btn
                                        rounded
                                        color="primary"
                                        class="mx-2"
                                        @click="openMultipleSend"
                                    >
                                        <v-icon class="mr-1">
                                            mdi-send
                                        </v-icon>
                                        {{
                                            $vuetify.breakpoint.smAndDown
                                                ? ''
                                                : 'SEND ITEMS'
                                        }}
                                    </v-btn>
                                    <v-btn
                                        rounded
                                        color="primary"
                                        class="mx-2"
                                        @click="openMultipleQuality"
                                        v-if="
                                            user.permissions.includes(
                                                'markQualityRegisters'
                                            )
                                        "
                                    >
                                        <v-icon class="mr-1">
                                            mdi-check-bold
                                        </v-icon>
                                        {{
                                            $vuetify.breakpoint.smAndDown
                                                ? ''
                                                : 'QUALITY'
                                        }}
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </template>
                    <!--ITEMS-->
                    <template v-slot:[`item.id`]="{ index }">
                        <p class="my-0">
                            {{ index + 1 }}
                        </p>
                    </template>
                    <template v-slot:[`item.mass`]="{ item }">
                        <p class="my-0">
                            {{ item.mass.toFixed(2) }}
                        </p>
                    </template>
                    <template v-slot:[`item.paintingArea`]="{ item }">
                        <p class="my-0">
                            {{ item.paintingArea.toFixed(2) }}
                        </p>
                    </template>
                    <template v-slot:[`item.finishAndUCCode`]="{ item }">
                        <p class="my-0">
                            {{
                                item.dataFinishAndUCCode &&
                                    item.dataFinishAndUCCode.name
                            }}
                        </p>
                    </template>
                    <template v-slot:[`item.description`]="{ item }">
                        <p class="my-0" style="max-width: 250px">
                            {{ item.description }}
                        </p>
                    </template>

                    <template v-slot:[`item.notes`]="{ item }">
                        <p class="my-0" style="max-width: 250px">
                            {{ item.notes }}
                        </p>
                    </template>
                    <template v-slot:[`item.processes`]="{ item }">
                        <div
                            v-for="process in item.productionInfo"
                            :key="process.id"
                        >
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <p class="my-0 text-left" v-on="on">
                                        {{ process.name }}:
                                        {{ process.available }} -
                                        {{ process.produced || 0 }} -
                                        {{ process.delivered }}
                                    </p>
                                </template>
                                <span>
                                    {{ process.name }}: <br />
                                    Available =
                                    {{ process.available || 0 }} ({{
                                        ((process.received || 0) * 100) /
                                            item.quantity
                                    }}%)<br />
                                    Produced =
                                    {{ process.produced || 0 }} ({{
                                        ((process.produced || 0) * 100) /
                                            item.quantity
                                    }}%)<br />
                                    Delivered =
                                    {{ process.delivered }} ({{
                                        ((process.delivered || 0) * 100) /
                                            item.quantity
                                    }}%)
                                </span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                        <div>
                            <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        x-small
                                        icon
                                        class="mr-1"
                                        color="white"
                                        style="background-color: #2b81d6;"
                                        v-on="on"
                                    >
                                        <p class="my-0">
                                            {{ calculateQualityPending(item) }}
                                        </p>
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        icon
                                        class="mr-1"
                                        color="white"
                                        style="background-color: #2fbf56;"
                                        v-on="on"
                                    >
                                        <p class="my-0">
                                            {{ calculateReleasedQty(item) }}
                                        </p>
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        icon
                                        class="mr-1"
                                        color="white"
                                        style="background-color: #ffa41d;"
                                        v-on="on"
                                    >
                                        <p class="my-0">
                                            {{ calculateReprocessQty(item) }}
                                        </p>
                                    </v-btn>
                                    <v-btn
                                        x-small
                                        icon
                                        class="mr-1"
                                        color="white"
                                        style="background-color: #FF2610;"
                                        v-on="on"
                                    >
                                        <p class="my-0">
                                            {{ calculateRejectedQty(item) }}
                                        </p>
                                    </v-btn>
                                </template>
                                <span>
                                    Pending :
                                    {{ calculateQualityPending(item) || 0 }}
                                    <br />
                                    Released :
                                    {{ calculateReleasedQty(item) }} <br />
                                    Reprocess :
                                    {{ calculateReprocessQty(item) }} <br />
                                    Rejected :
                                    {{ calculateRejectedQty(item) }} <br />
                                </span>
                            </v-tooltip>
                        </div>
                    </template>
                    <template v-slot:[`item.files`]="{ item }">
                        <div
                            class="d-flex justify-center my-0"
                            v-if="item.files != undefined"
                        >
                            <a
                                v-if="
                                    item.files.includes(
                                        `${item.partNumber}.pdf`
                                    )
                                "
                                class="mx-1"
                                @click="loadFiles(item, 'pdf')"
                            >
                                PDF
                            </a>
                            <a
                                v-if="
                                    item.files.includes(
                                        `${item.partNumber}.dxf`
                                    )
                                "
                                class="mx-1"
                                @click="loadFiles(item, 'dxf')"
                            >
                                DXF
                            </a>
                            <a
                                v-if="
                                    item.files.includes(
                                        `${item.partNumber}.stp`
                                    )
                                "
                                class="mx-1"
                                @click="loadFiles(item, 'stp')"
                            >
                                STP
                            </a>
                        </div>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div>
                            <v-menu rounded offset-y>
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        depressed
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item
                                        @click="editItem(item)"
                                        v-if="
                                            (workOrder.status == 'inProgress' ||
                                                workOrder.status ==
                                                    'rejected') &&
                                                !readOnlyWO
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Edit Item
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="openAddItemFiles(item)"
                                        v-if="
                                            (workOrder.status == 'inProgress' ||
                                                workOrder.status ==
                                                    'rejected') &&
                                                !readOnlyWO
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-upload</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Add Item Files
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="openDeleteItem(item)"
                                        v-if="
                                            (workOrder.status == 'inProgress' ||
                                                workOrder.status ==
                                                    'rejected') &&
                                                !readOnlyWO
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Delete Item
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="copyItem(item)"
                                        v-if="
                                            (workOrder.status == 'inProgress' ||
                                                workOrder.status ==
                                                    'rejected') &&
                                                !readOnlyWO
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-content-copy</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Copy Item
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="openArrangeProcesses(item)"
                                        v-if="
                                            workOrder.status == 'approved' &&
                                                !readOnlyWO &&
                                                !user.operatorRole &&
                                                user.permissions.includes(
                                                    'updateWOProcesses'
                                                )
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-swap-vertical</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Arrange Processes
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="registerProduction(item)"
                                        v-if="
                                            workOrder.status == 'approved' &&
                                                !readOnlyWO &&
                                                item &&
                                                item.processes.length > 0 &&
                                                activateActions(item)
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-tools</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Register Production
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="sendToPrevProcess(item)"
                                        v-if="
                                            workOrder.status == 'approved' &&
                                                !readOnlyWO &&
                                                item &&
                                                item.processes.length > 0 &&
                                                activateActions(item) &&
                                                item.processes[0] !=
                                                    user.process.id
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon
                                                >mdi-arrow-left-circle-outline</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Return To Previous Process
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="sendToNextProcess(item)"
                                        v-if="
                                            workOrder.status == 'approved' &&
                                                !readOnlyWO &&
                                                item &&
                                                item.processes.length > 1 &&
                                                activateActions(item)
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-send</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Send To Next Process
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="registerQuality(item)"
                                        v-if="
                                            !readOnlyWO &&
                                                item &&
                                                item.processes.length > 1 &&
                                                activateActions(item) &&
                                                user.permissions.includes(
                                                    'markQualityRegisters'
                                                ) &&
                                                workOrder.status == 'approved'
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-check-bold</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Register Quality
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="openProductionLog(item)"
                                        v-if="
                                            item &&
                                                item.processes.length > 1 &&
                                                workOrder.status != 'inProgress'
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon
                                                >mdi-book-cog-outline</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Production Log
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="openDeliveryLog(item)"
                                        v-if="
                                            item &&
                                                item.processes.length > 1 &&
                                                workOrder.status != 'inProgress'
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon
                                                >mdi-book-arrow-right-outline</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Delivery Log
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="openQualityLog(item)"
                                        v-if="
                                            item &&
                                                item.processes.length > 1 &&
                                                workOrder.status != 'inProgress'
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon
                                                >mdi-book-check-outline</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Quality Log
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="openDeleteItem(item)"
                                        v-if="
                                            workOrder.status == 'approved' &&
                                                !readOnlyWO &&
                                                permissionToCancelWO &&
                                                (!item.production ||
                                                    item.production.length == 0)
                                        "
                                        :disabled="
                                            item.production &&
                                                item.production.length > 0
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon>mdi-trash-can</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Delete Item
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                    <!--FOOTER-->
                    <template v-slot:[`body.append`]>
                        <tr>
                            <td class="font-weight-bold text-center"></td>
                            <td class="font-weight-bold text-center">
                                TOTAL
                            </td>
                            <td class="font-weight-bold"></td>
                            <td class="font-weight-bold text-center">
                                {{ totalMass.toFixed(2) }} (kgs)
                            </td>
                            <td class="font-weight-bold text-center">
                                {{ totalPaintingArea.toFixed(2) }} (m2)
                            </td>
                            <td class="font-weight-bold"></td>
                            <td class="font-weight-bold text-center">
                                {{ totalQuantity }}
                            </td>
                            <td class="font-weight-bold"></td>
                            <td class="font-weight-bold"></td>
                            <td class="font-weight-bold"></td>
                            <td class="font-weight-bold"></td>
                            <td
                                class="font-weight-bold"
                                v-if="
                                    workOrder.status == 'approved' ||
                                        workOrder.status == 'closed'
                                "
                            ></td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <div class="ma-0 pa-0">
            <v-row
                no-gutters
                v-if="
                    (workOrder.status == 'inProgress' ||
                        workOrder.status == 'rejected') &&
                        !readOnlyWO
                "
                class="mt-5"
            >
                <v-col cols="12" class="d-flex">
                    <v-btn
                        v-if="
                            workOrder.projectManager == user.id ||
                                workOrder.createdBy.id == user.id
                        "
                        rounded
                        color="red"
                        class="white--text"
                        @click="openDeleteWorkOrder"
                    >
                        DELETE
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        rounded
                        color="primary"
                        @click="sendWO"
                        :loading="loading"
                        :disabled="notItemsCreated()"
                    >
                        SEND
                    </v-btn>
                </v-col>
            </v-row>
            <v-row
                no-gutters
                v-if="workOrder.status == 'sent' && !readOnlyWO"
                class="mt-5"
            >
                <v-col cols="12" class="d-flex">
                    <v-btn
                        rounded
                        color="red"
                        class="white--text"
                        @click="openRejectWO"
                        :loading="loading"
                    >
                        REJECT
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        v-if="workOrder.projectManager == user.id"
                        rounded
                        color="primary"
                        @click="approve"
                        :loading="loading"
                    >
                        APPROVE
                    </v-btn>
                </v-col>
            </v-row>
            <v-row
                no-gutters
                v-if="workOrder.status == 'approved' && !readOnlyWO"
                class="mt-5"
            >
                <v-col cols="12" class="d-flex">
                    <v-btn
                        v-if="permissionToCancelWO"
                        rounded
                        color="black"
                        @click="openCancelDialog = true"
                        :loading="loading"
                        :disabled="processesInitiated()"
                        class="white--text text-capitalize"
                    >
                        CANCEL
                    </v-btn>
                    <v-btn
                        v-if="permissionToRejectWO && permissionToApproveWO"
                        rounded
                        color="red"
                        class="white--text ml-4"
                        @click="openRejectWO"
                        :loading="loading"
                        :disabled="processesInitiated()"
                    >
                        REJECT
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        rounded
                        color="#ff9c0a"
                        @click="openClosingDialog"
                        :loading="loading"
                        class="white--text text-capitalize"
                        :disabled="disableCloseProcess()"
                    >
                        CLOSE
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <!--Item Form-->
        <v-dialog
            v-model="openItemForm"
            persistent
            max-width="800px"
            :retain-focus="false"
        >
            <ItemForm
                v-if="openItemForm"
                :workOrder="workOrder"
                :partNumbers="partNumbers"
                :item="selectedItem"
                :originalItem="originalItem"
                :createForm="createForm"
                :settingProcesses="settingProcesses"
                :settingFinish="settingFinish"
                @replaceItem="replaceItem"
                @close="closeAddItem"
                @addItem="addItem"
            />
        </v-dialog>
        <!--Add Item Files-->
        <v-dialog
            v-model="openItemFiles"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <AddItemFiles
                v-if="openItemFiles"
                :partNumber="selectedItem.partNumber"
                :partNumbers="partNumbers"
                :workOrder="workOrder"
                :selectedItem="selectedItem"
                :settingProcesses="settingProcesses"
                :settingFinish="settingFinish"
                @openPartNumbersNotFound="openPartNumbersNotFound"
                @addItems="addItems"
                @addFiles="addFiles"
                @close="closeAddItemFiles"
            />
            <v-alert
                v-if="partNumbersNotFound.length > 0"
                type="error"
                style="position: absolute; bottom: 0px; right: 0px;"
            >
                <div class="d-flex">
                    <p class="my-0">
                        The following part numbers do not belong to the project:
                        <br />
                        {{ partNumbersNotFound.join(', ') }}
                    </p>
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="partNumbersNotFound = []"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </div>
            </v-alert>
        </v-dialog>
        <!--Upload Files-->
        <v-dialog
            v-model="uploadFiles"
            persistent
            max-width="800px"
            :retain-focus="false"
        >
            <UploadFiles
                v-if="uploadFiles"
                :workOrder="workOrder"
                @addFiles="addFiles"
                @close="closeUploadFiles"
            />
        </v-dialog>
        <!--ALERT-->
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <!--Delete Item-->
        <v-dialog
            v-model="deleteWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="deleteWarning">
                <v-card-title class="text-h5">Delete Items</v-card-title>
                <v-card-text>
                    Are you sure you want to perform this operation ?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDeleteItem">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="removeItems"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Delete Work Order-->
        <v-dialog
            v-model="deleteWorkOrderWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="deleteWorkOrderWarning">
                <v-card-title class="text-h5">Delete Work Order</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this work order?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeDeleteWorkOrder">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteWorkOrder"
                        text
                        color="error"
                        :loading="loading"
                    >
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Reject Work Orders-->
        <v-dialog
            v-model="rejectWOForm"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="rejectWOForm">
                <v-card-title class="text-h5">Reject</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you decide to reject this work
                            order
                        </v-col>
                    </v-row>
                    <v-form v-model="validRejectForm">
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="rejectedNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeRejectWO">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="rejectWO"
                        text
                        color="error"
                        :loading="loading"
                        :disabled="!rejectedNotes"
                    >
                        Reject
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--ADD FILES-->
        <v-dialog
            :retain-focus="false"
            v-model="workOrderFiles"
            persistent
            max-width="400px"
        >
            <v-card v-if="workOrderFiles">
                <v-card-title class="text-h5">Add File</v-card-title>
                <v-card-text>
                    <v-file-input
                        class="mt-3 pb-0"
                        v-model="file"
                        label="File"
                    ></v-file-input>
                </v-card-text>
                <v-card-actions class="pb-3 pt-0">
                    <v-btn
                        text
                        color="secondary"
                        @click="workOrderFiles = false"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="saveFile"
                        color="primary"
                        :loading="loadingFiles"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--ARRANGE PROCESSES-->
        <v-dialog
            :retain-focus="false"
            v-model="arrangeProcesses"
            persistent
            max-width="450px"
        >
            <ArrangeProcesses
                v-if="arrangeProcesses"
                :item="selectedItem"
                :originalItem="originalItem"
                :settingsProcesses="settingProcesses"
                :workOrder="workOrder"
                :multipleItemSelect="false"
                @closeDialog="closeArrangeProcesses"
                @saveProcesses="saveProcesses"
            />
        </v-dialog>
        <!--REGISTER PRODUCTION-->
        <v-dialog
            :retain-focus="false"
            v-model="productionDialog"
            persistent
            max-width="500px"
        >
            <RegisterProduction
                v-if="productionDialog"
                :item="selectedItem"
                :workOrder="workOrder"
                :settings="settingProcesses"
                :user="user"
                @closeDialog="closeRegisterProduction"
            />
        </v-dialog>
        <!--RETURN TO THE PREV PROCESS-->
        <v-dialog
            :retain-focus="false"
            v-model="prevProcessDialog"
            persistent
            max-width="500px"
        >
            <PrevProcess
                v-if="prevProcessDialog"
                :item="selectedItem"
                :workOrder="workOrder"
                :user="user"
                @closeDialog="closeSendToPrevProcess"
            />
        </v-dialog>
        <!--MOVE TO THE NEXT PROCESS-->
        <v-dialog
            :retain-focus="false"
            v-model="processDialog"
            persistent
            max-width="500px"
        >
            <NextProcess
                v-if="processDialog"
                :item="selectedItem"
                :workOrder="workOrder"
                :user="user"
                @closeDialog="closeSendToNextProcess"
            />
        </v-dialog>
        <!--Multiple Register-->
        <v-dialog
            :retain-focus="false"
            v-model="multipleRegisterDialog"
            persistent
            max-width="900px"
        >
            <MultipleRegister
                v-if="multipleRegisterDialog"
                :workOrder="workOrder"
                :user="user"
                :settings="settingProcesses"
                @closeDialog="closeMultipleRegister"
                @replaceItems="closeRegisterProduction"
            />
        </v-dialog>
        <!--Multiple Send-->
        <v-dialog
            :retain-focus="false"
            v-model="multipleSendDialog"
            persistent
            max-width="900px"
        >
            <MultipleSend
                v-if="multipleSendDialog"
                :workOrder="workOrder"
                :user="user"
                :processes="settingProcesses"
                @closeDialog="closeMultipleSend"
            />
        </v-dialog>
        <!--Multiple Packing-->
        <v-dialog
            :retain-focus="false"
            v-model="multiplePackingDialog"
            persistent
            max-width="900px"
        >
            <MultiplePacking
                v-if="multiplePackingDialog"
                :workOrder="workOrder"
                @closeDialog="closeMultiplePacking"
            />
        </v-dialog>
        <!--Multiple Processes-->
        <v-dialog
            :retain-focus="false"
            v-model="multipleProcessDialog"
            persistent
            max-width="900px"
        >
            <MultipleProcesses
                v-if="multipleProcessDialog"
                :processes="settingProcesses"
                :workOrder="workOrder"
                :user="user"
                @closeDialog="closeMultipleProcesses"
            />
        </v-dialog>
        <!--Delete Item-->
        <v-dialog
            v-model="openWarning"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="openWarning">
                <v-card-title class="text-h5">
                    Close Work Order {{ workOrder.code }}</v-card-title
                >
                <v-card-text>
                    {{ message }}
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeWarning" rounded>
                        NO
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="closeWO"
                        text
                        color="error"
                        rounded
                        :loading="loading"
                    >
                        YES
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Cancel WO-->
        <v-dialog
            v-model="openCancelDialog"
            persistent
            max-width="400px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="openCancelDialog">
                <v-card-title class="text-h5">
                    Cancel Work Order {{ workOrder.code }}</v-card-title
                >
                <v-card-text>
                    Are you sure you want to cancel this work order ?
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="openCancelDialog = false"
                        rounded
                    >
                        NO
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteWorkOrder"
                        text
                        color="error"
                        rounded
                        :loading="loading"
                    >
                        YES
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--QUALITY PROCESS-->
        <v-dialog
            :retain-focus="false"
            v-model="qualityDialog"
            persistent
            max-width="300px"
        >
            <QualityProcess
                v-if="qualityDialog"
                :item="selectedItem"
                :workOrder="workOrder"
                :user="user"
                @closeDialog="closeQualityProcess"
            />
        </v-dialog>
        <!--MULTIPLE QUALITY PROCESS-->
        <v-dialog
            :retain-focus="false"
            v-model="multipleQualityDialog"
            persistent
            max-width="1200px"
        >
            <MultipleQualityProcess
                v-if="multipleQualityDialog"
                :workOrder="workOrder"
                :user="user"
                @closeDialog="closeMultipleQualityProcess"
            />
        </v-dialog>
        <!--ITEM LOG-->
        <v-dialog
            :retain-focus="false"
            v-model="itemLogDialog"
            persistent
            max-width="1200px"
        >
            <ItemLog
                v-if="itemLogDialog"
                :viewType="viewType"
                :users="users"
                :selectedItem="selectedItem"
                @closeDialog="itemLogDialog = false"
            />
        </v-dialog>
    </v-container>
</template>

<script>
import moment from 'moment'
import API from '@/services/api'
import { mapMutations } from 'vuex'
import qrious from 'qrious'
import _ from 'lodash'
import { loadImage, deleteImage } from '@/helpers/imageHandler.js'
import { saveFile } from '@/services/storage/saveFile.js'
import { deleteFile } from '@/services/storage/deleteFile.js'
import { openFile } from '@/services/storage/openFile.js'

export default {
    name: 'WOGeneralInfo',
    props: {
        workOrder: {
            type: Object,
            required: true,
        },
        readOnlyWO: {
            type: Boolean,
            default: () => false,
        },
    },
    components: {
        ItemForm: () => import('@/components/WorkOrders/ItemForm.vue'),
        AddItemFiles: () => import('@/components/WorkOrders/AddItemFiles.vue'),
        UploadFiles: () => import('@/components/WorkOrders/UploadFiles.vue'),
        ArrangeProcesses: () =>
            import('@/components/WorkOrders/ArrangeProcesses.vue'),
        NextProcess: () => import('@/components/WorkOrders/NextProcess.vue'),
        PrevProcess: () => import('@/components/WorkOrders/PrevProcess.vue'),
        RegisterProduction: () =>
            import('@/components/WorkOrders/RegisterProduction.vue'),
        MultipleProcesses: () =>
            import('@/components/WorkOrders/MultipleProcesses.vue'),
        MultipleSend: () => import('@/components/WorkOrders/MultipleSend.vue'),
        MultiplePacking: () =>
            import('@/components/WorkOrders/MultiplePacking.vue'),
        MultipleRegister: () =>
            import('@/components/WorkOrders/MultipleRegister.vue'),
        QualityProcess: () =>
            import('@/components/WorkOrders/QualityProcess.vue'),
        MultipleQualityProcess: () =>
            import('@/components/WorkOrders/MultipleQualityProcess.vue'),
        ItemLog: () => import('@/components/WorkOrders/ItemLog.vue'),
    },
    inject: {
        approveWO: {
            from: 'approveWO',
            default() {
                return null
            },
        },
    },
    data: () => ({
        multipleRegisterDialog: false,
        openCancelDialog: false,
        workOrderFiles: false,
        createForm: false,
        originalItem: {},
        deleteWorkOrderWarning: false,
        deleteWarning: false,
        alertMessage: '',
        activateAlert: false,
        uploadFiles: false,
        selectedItem: {},
        openItemFiles: false,
        openItemForm: false,
        loading: false,
        projectManagerName: undefined,
        rejectWOForm: false,
        rejectedNotes: undefined,
        validRejectForm: false,
        headers: [
            {
                text: 'ID',
                value: 'id',
                align: 'center',
                sortable: false,
                width: 50,
            },
            {
                text: 'PART NUMBER',
                value: 'partNumber',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'left',
                sortable: false,
            },
            {
                text: 'MASS',
                value: 'mass',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PAINTING AREA',
                value: 'paintingArea',
                align: 'center',
                sortable: false,
            },
            {
                text: 'FINISH & UC CODE',
                value: 'finishAndUCCode',
                align: 'center',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                align: 'center',
                sortable: false,
            },
            {
                text: 'NOTES',
                value: 'notes',
                align: 'left',
                sortable: false,
            },
            {
                text: 'PROCESSES',
                value: 'processes',
                align: 'left',
                sortable: false,
            },
            {
                text: 'FILES',
                value: 'files',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        height: 0,
        partNumbers: [],
        partNumbersNotFound: [],
        companyId: JSON.parse(localStorage.getItem('company')),
        settingProcesses: undefined,
        settingFinish: undefined,
        removeAll: false,
        file: null,
        loadingFiles: false,
        prevNotes: null,
        arrangeProcesses: false,
        user: {},
        processDialog: false,
        productionDialog: false,
        rules: {
            required: v => !!v || 'The value is required',
        },
        productionInfo: [],
        openWarning: false,
        multipleProcessDialog: false,
        multipleSendDialog: false,
        multiplePackingDialog: false,
        permissionToCancelWO: false,
        message: '',
        permissionToRejectWO: false,
        permissionToApproveWO: false,
        processToShow: undefined,
        showProcesses: false,
        packingDialog: false,
        qualityProcess: {
            id: 'quality',
            name: 'Quality',
        },
        readyForPackingProcess: {
            id: 'readyForPacking',
            name: 'Ready for packing',
        },
        packedProcess: {
            id: 'packed',
            name: 'Packed',
        },
        prevProcessDialog: false,
        qualityDialog: false,
        multipleQualityDialog: false,
        viewType: null,
        itemLogDialog: false,
        users: [],
    }),
    computed: {
        filterWorkOrders() {
            return this.workOrder.items ? this.workOrder.items : []
        },
        validateOrders() {
            return !(
                this.workOrder.items?.filter(
                    item => item.files && item.files.length > 0
                ).length > 0
            )
        },
        totalMass() {
            return this.workOrder.items
                ? this.workOrder.items.reduce(
                      (accumulator, item) =>
                          accumulator + item.mass * item.quantity,
                      0
                  )
                : 0
        },
        totalPaintingArea() {
            return this.workOrder.items
                ? this.workOrder.items.reduce(
                      (accumulator, item) =>
                          accumulator + item.paintingArea * item.quantity,
                      0
                  )
                : 0
        },
        totalQuantity() {
            return this.workOrder.items
                ? this.workOrder.items.reduce(
                      (accumulator, item) => accumulator + item.quantity,
                      0
                  )
                : 0
        },
    },
    watch: {
        workOrder: async function() {
            this.setUpdatedData()
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.onResize()
            this.prevNotes = this.workOrder.notes
            // set project manager
            let projectManager = undefined
            if (typeof this.workOrder.project.projectManager == 'string') {
                projectManager = await API.getUser(
                    this.workOrder.project.projectManager
                )
            } else {
                projectManager = this.workOrder.project.projectManager
            }
            this.projectManagerName = projectManager.name
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            // retrieve permission to cancel a WO
            this.permissionToCancelWO = user.permissions.includes('cancelWO')
            this.permissionToRejectWO = user.permissions.includes('rejectWO')
            this.permissionToApproveWO = user.permissions.includes('approvedWO')
            // qr code
            new qrious({
                element: document.getElementById('qr'),
                value: `https://es-metals-project-agenda.web.app/work-orders/main-work-orders?orderId=${this.workOrder.id}`,
            })
            await this.setOrderData()
            if (user.permissions.includes('watchProcesses')) {
                this.showProcesses = true
            } else if (user.process) {
                this.processToShow = user.process
                this.showProcesses = true
            }

            if (
                this.workOrder.status == 'approved' ||
                this.workOrder.status == 'closed'
            ) {
                this.headers.splice(9, 0, {
                    text: 'STATUS',
                    value: 'status',
                    align: 'center',
                    sortable: false,
                    width: 100,
                })
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        openClosingDialog() {
            try {
                if (!this.allItemsCompleted()) {
                    this.message =
                        'You are about to close the work order without completing all the processes, are you sure to carry out this operation?'
                } else {
                    this.message =
                        'Are you sure you want to close this work order ?'
                }
                this.openWarning = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        processesInitiated() {
            for (const item of this.workOrder.items || []) {
                if (item.production && item.production.length > 0) {
                    return true
                }
            }
            return false
        },
        async deleteFile(file) {
            try {
                this.loading = true
                await deleteFile(
                    file,
                    `${this.companyId}/workOrders/${this.workOrder.id}/attachments`
                )
                this.workOrder.attachments = this.workOrder.attachments.filter(
                    f => f != file
                )
                await API.workOrderAttachments({
                    workOrderId: this.workOrder.id,
                    files: this.workOrder.attachments,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async openFileAttached(file) {
            try {
                this.loading = true
                await openFile(
                    file,
                    `${this.companyId}/workOrders/${this.workOrder.id}/attachments`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async saveFile() {
            try {
                this.loadingFiles = true
                await saveFile(
                    this.file,
                    `${this.companyId}/workOrders/${this.workOrder.id}/attachments`
                )
                this.workOrder.attachments = [
                    ...(this.workOrder.attachments || []),
                    this.file.name,
                ]
                await API.workOrderAttachments({
                    workOrderId: this.workOrder.id,
                    files: this.workOrder.attachments,
                })
                this.closeWorkOrderFiles()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loadingFiles = false
            }
        },
        closeWorkOrderFiles() {
            this.workOrderFiles = false
            this.file = null
        },
        openWorkOrderFiles() {
            this.workOrderFiles = true
        },
        closeWarning() {
            this.openWarning = false
        },
        async closeWO() {
            try {
                this.loading = true
                await API.closeWorkOrder({
                    workOrderId: this.workOrder.id,
                    notificationId: 'ar0vX28u4hadZW2pVn9n',
                })
                this.activateAlert = true
                this.alertMessage = 'Work Order Successfully Closed'
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.closeWarning()
            }
        },
        notFoundFile() {
            let response = false
            for (const item of this.workOrder.items || []) {
                if (
                    !item.files ||
                    !item.files.includes(`${item.partNumber}.pdf`)
                ) {
                    response = true
                    break
                }
            }
            return response
        },
        notItemsCreated() {
            return this.workOrder.items && this.workOrder.items.length > 0
                ? false
                : true
        },
        replaceItem(item) {
            // retrieve data
            if (item.processes && item.processes.length > 0) {
                item.dataProcesses = this.settingProcesses.processes.filter(
                    process => item.processes.includes(process.id)
                )
            }
            if (item.finishAndUCCode) {
                item.dataFinishAndUCCode = this.settingFinish.finishes.find(
                    e => e.id == item.finishAndUCCode
                )
            }
            const index = this.workOrder.items.findIndex(i => i.id == item.id)
            if (index >= 0) {
                this.workOrder.items.splice(index, 1, {
                    ...this.workOrder.items[index],
                    ...item,
                })
                this.workOrder.items = [...this.workOrder.items]
            }
        },
        editItem(item) {
            this.selectedItem = _.cloneDeep(item)
            this.originalItem = _.cloneDeep(item)
            this.createForm = false
            this.openItemForm = true
        },
        async deleteWorkOrder() {
            try {
                this.loading = true
                await API.deleteWorkOrder(this.workOrder.id)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeDeleteWorkOrder() {
            this.deleteWorkOrderWarning = false
        },
        openDeleteWorkOrder() {
            this.deleteWorkOrderWarning = true
        },
        async copyItem(item) {
            let copiedItem = _.cloneDeep(item)
            delete copiedItem.id
            delete copiedItem.partNumber
            delete copiedItem.partNumberId
            delete copiedItem.dataProcesses
            delete copiedItem.files
            delete copiedItem.dataFinishAndUCCode
            this.selectedItem = _.cloneDeep(copiedItem)
            this.originalItem = _.cloneDeep(copiedItem)
            this.createForm = true
            this.openItemForm = true
        },
        async removeItems() {
            if (this.removeAll) {
                await this.deleteAllItems()
            } else {
                await this.deleteItem()
            }
        },
        async deleteAllItems() {
            try {
                this.loading = true
                for (let item of this.workOrder.items) {
                    if (item.files) {
                        for (const file of item.files || []) {
                            await deleteImage(
                                `${this.companyId}/workOrders/${this.workOrder.id}/items`,
                                file
                            )
                        }
                    }
                    await API.deleteWorkOrderAllItems(this.workOrder.id)
                }
                // remove local item
                this.workOrder.items = []
                this.closeDeleteItem()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async deleteItem() {
            try {
                this.loading = true
                await API.deleteWorkOrderItem({
                    workOrderId: this.workOrder.id,
                    itemId: this.selectedItem.id,
                })
                if (this.selectedItem.files) {
                    for (const file of this.selectedItem.files) {
                        await deleteImage(
                            `${this.companyId}/workOrders/${this.workOrder.id}/items`,
                            file
                        )
                    }
                }
                // remove local item
                this.workOrder.items = [
                    ...this.workOrder.items.filter(
                        item => item.id != this.selectedItem.id
                    ),
                ]
                this.closeDeleteItem()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeDeleteItem() {
            this.removeAll = false
            this.selectedItem = {}
            this.deleteWarning = false
        },
        openDeleteItem(item) {
            this.removeAll = false
            this.selectedItem = _.cloneDeep(item)
            this.deleteWarning = true
        },
        openDeleteAllItem() {
            this.removeAll = true
            this.deleteWarning = true
        },
        async loadFiles(item, extension) {
            try {
                this.loading = true
                var url = await loadImage(
                    `${this.companyId}/workOrders/${this.workOrder.id}/items`,
                    item.partNumber + '.' + extension
                )
                if (url) {
                    setTimeout(() => {
                        window.open(url, '_blank')
                        this.loading = false
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        addFiles(newItems) {
            for (const newItem of newItems) {
                const index = this.workOrder.items.findIndex(
                    item => item.id == newItem.itemId
                )
                if (index >= 0) {
                    if (
                        !this.workOrder.items[index].files ||
                        this.workOrder.items[index].files.length === 0
                    ) {
                        this.workOrder.items.splice(index, 1, {
                            ...this.workOrder.items[index],
                            files: newItem.files,
                        })
                    } else {
                        this.workOrder.items.splice(index, 1, {
                            ...this.workOrder.items[index],
                            files: [
                                ...this.workOrder.items[index].files.filter(
                                    file => !newItem.files.includes(file)
                                ),
                                ...newItem.files,
                            ],
                        })
                    }
                }
            }
            this.workOrder.items = [...this.workOrder.items]
            this.closeUploadFiles()
        },
        async addItems(items) {
            try {
                for (const item of items) {
                    this.addItem(item)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openPartNumbersNotFound(partNumbers) {
            this.partNumbersNotFound = partNumbers
        },
        async addItem(item) {
            try {
                this.loading = true
                const partNumber = this.partNumbers.find(
                    pn => pn.id === item.partNumberId
                )
                if (partNumber) {
                    item.partNumber = partNumber.code
                }
                // retrieve data
                if (this.settingProcesses.id) {
                    if (item.processes?.length > 0) {
                        item.dataProcesses = this.settingProcesses.processes.filter(
                            process => item.processes.includes(process.id)
                        )
                    }
                }
                if (this.settingFinish.id) {
                    if (item.finishAndUCCode) {
                        item.dataFinishAndUCCode = this.settingFinish.finishes.find(
                            e => e.id == item.finishAndUCCode
                        )
                    }
                }
                if (!this.workOrder.items) {
                    this.workOrder.items = [item]
                } else {
                    this.workOrder.items.push(item)
                }
                //TODO: use local items
                this.workOrder = _.clone(this.workOrder)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openUploadFiles() {
            this.uploadFiles = true
        },
        closeUploadFiles() {
            this.uploadFiles = false
        },
        importFiles() {
            this.openItemFiles = true
        },
        openAddItemFiles(item) {
            this.selectedItem = _.cloneDeep(item)
            this.openItemFiles = true
        },
        closeAddItemFiles() {
            this.selectedItem = {}
            this.originalItem = {}
            this.openItemFiles = false
        },
        openAddItem() {
            this.createForm = true
            this.openItemForm = true
        },
        closeAddItem() {
            this.createForm = false
            this.selectedItem = {}
            this.originalItem = {}
            this.openItemForm = false
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                details: { clientHeight: detailsHeight },
            } = this.$refs
            this.height =
                window.innerHeight - containerHeight - detailsHeight + 27
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('L')} ${moment
                .unix(seconds)
                .format('LT')}`
        },
        async sendWO() {
            try {
                this.loading = true
                await API.updateWorkOrder({
                    workOrderId: this.workOrder.id,
                    status: 'sent',
                    notificationId: 'sZhuzxwPqvSsy2wFSK66',
                })
                this.activateAlert = true
                this.alertMessage = 'Work Order Successfully Sent for Approval'
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async approve() {
            try {
                this.loading = true
                await API.updateWorkOrder({
                    workOrderId: this.workOrder.id,
                    status: 'approved',
                    notificationId: 'pFDh25EcR74EMiuypq8M',
                })
                this.approveWO()
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        openRejectWO() {
            this.rejectWOForm = true
        },
        closeRejectWO() {
            this.rejectWOForm = false
        },
        async rejectWO() {
            try {
                this.loading = true
                await API.updateWorkOrder({
                    workOrderId: this.workOrder.id,
                    status: 'rejected',
                    rejectedNotes: this.rejectedNotes,
                    notificationId: 'wu0mfm4lOUmF0G1vdY16',
                })
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.rejectedNotes = undefined
            }
        },
        async saveNotes() {
            if (this.prevNotes != this.workOrder.notes) {
                try {
                    await API.updateWorkOrderNotes({
                        workOrderId: this.workOrder.id,
                        notes: this.workOrder.notes,
                    })
                } catch (error) {
                    this.setErrorItems({
                        source: this.$options.name,
                        message: error.message,
                    })
                }
            }
        },
        openArrangeProcesses(item) {
            this.selectedItem = _.cloneDeep(item)
            this.originalItem = _.cloneDeep(item)
            this.arrangeProcesses = true
        },
        closeArrangeProcesses() {
            this.selectedItem = {}
            this.originalItem = {}
            this.arrangeProcesses = false
        },
        saveProcesses(processes) {
            const itemIndex = this.filterWorkOrders.findIndex(
                i => i.id === this.selectedItem.id
            )
            this.filterWorkOrders[itemIndex].dataProcesses = processes
            this.filterWorkOrders[itemIndex].processes = processes.map(
                process => process.id
            )
            this.closeArrangeProcesses()
            for (let item of this.workOrder.items) {
                item.productionInfo = this.calculateFinished(item)
            }
        },
        sendToPrevProcess(item) {
            this.selectedItem = _.cloneDeep(item)
            this.prevProcessDialog = true
        },
        sendToNextProcess(item) {
            this.selectedItem = _.cloneDeep(item)
            this.processDialog = true
        },
        registerProduction(item) {
            this.selectedItem = _.cloneDeep(item)
            this.productionDialog = true
        },
        closeRegisterProduction(items) {
            this.selectedItem = _.cloneDeep({})
            this.productionDialog = false
            if (items) {
                items.forEach(item => {
                    const index = this.workOrder.items.findIndex(
                        workOrderItem => workOrderItem.id === item.id
                    )
                    if (index > -1) {
                        this.workOrder.items[index].production = item.production
                        this.workOrder.items[
                            index
                        ].productionInfo = this.calculateFinished(item)
                    }
                })
            }
            this.multipleRegisterDialog = false
        },
        closeSendToNextProcess(items) {
            this.selectedItem = _.cloneDeep({})
            this.processDialog = false
            if (items) {
                items.forEach(item => {
                    const index = this.workOrder.items.findIndex(
                        workOrderItem => workOrderItem.id === item.id
                    )
                    if (index > -1) {
                        this.workOrder.items[index].deliveries = item.deliveries
                    }
                })
            }
        },
        closeSendToPrevProcess(items) {
            this.selectedItem = _.cloneDeep({})
            this.prevProcessDialog = false
            if (items) {
                items.forEach(item => {
                    const index = this.workOrder.items.findIndex(
                        workOrderItem => workOrderItem.id === item.id
                    )
                    if (index > -1) {
                        this.workOrder.items[index].deliveries = item.deliveries
                    }
                })
            }
        },
        closePackingDialog(items) {
            this.selectedItem = _.cloneDeep({})
            this.packingDialog = false
            if (items) {
                items.forEach(item => {
                    const index = this.workOrder.items.findIndex(
                        workOrderItem => workOrderItem.id === item.id
                    )
                    if (index > -1) {
                        this.workOrder.items[index].production = item.production
                        this.workOrder.items[
                            index
                        ].productionInfo = this.calculateFinished(item)
                    }
                })
            }
        },
        activateActions(item) {
            return (
                !this.loading &&
                item.processes &&
                this.user.process &&
                item.processes.includes(this.user.process.id)
            )
        },
        calculateFinished(item) {
            let productionInfo = []

            let qualityProcess = {
                id: this.qualityProcess.id,
                name: this.qualityProcess.name,
                qty: 0,
                received: 0,
                delivered: 0,
                produced: 0,
                available: 0,
            }

            let packingProcess = {
                id: this.readyForPackingProcess.id,
                name: this.readyForPackingProcess.name,
                qty: 0,
                received: 0,
                delivered: 0,
                produced: 0,
                available: 0,
            }

            let packedProcess = {
                id: this.packedProcess.id,
                name: this.packedProcess.name,
                qty: 0,
                received: 0,
                delivered: 0,
                produced: 0,
                available: 0,
            }
            if (item && item.processes && this.settingProcesses) {
                item.processesToShow = _.cloneDeep(item.processes)

                if (this.processToShow) {
                    item.processesToShow = item.processesToShow.filter(
                        p => p == this.processToShow.id
                    )
                }
                if (item.processesToShow) {
                    item.processesToShow.forEach(process => {
                        const pIndex = item.processes.findIndex(
                            p => p == process
                        )

                        let totalProduced = 0
                        let totalDelivered = 0
                        let totalReceived = 0
                        if (item.production) {
                            let production = item.production.filter(
                                register => register.process.id === process
                            )
                            totalProduced = production.reduce(
                                (total, register) => total + register.qty,
                                0
                            )
                        }
                        if (item.deliveries) {
                            let deliveries = item.deliveries.filter(
                                register => register.prevProcess.id === process
                            )
                            totalDelivered = deliveries.reduce(
                                (total, register) => total + register.qty,
                                0
                            )

                            if (pIndex > 0) {
                                let received = item.deliveries.filter(
                                    register =>
                                        register.nextProcess.id == process
                                )
                                totalReceived = received.reduce(
                                    (total, register) => total + register.qty,
                                    0
                                )
                            } else {
                                totalReceived = item.quantity
                            }
                        }
                        if (pIndex == 0) {
                            totalReceived = item.quantity
                        }

                        item.dataProcesses = this.settingProcesses.processes.filter(
                            process => item.processes.includes(process.id)
                        )
                        let processIndex = item.dataProcesses.findIndex(
                            p => p.id == process
                        )
                        productionInfo.push({
                            name: item.dataProcesses[processIndex].name,
                            id: process,
                            produced: totalProduced,
                            delivered: totalDelivered,
                            received: totalReceived,
                            available: totalReceived - totalProduced,
                        })
                    })
                }
                //ready for packing process
                let totalProduced = item.production
                    ? item.production.reduce(
                          (total, register) =>
                              register.process.id ==
                              this.readyForPackingProcess.id
                                  ? total + register.qty
                                  : total,
                          0
                      )
                    : 0

                packingProcess.qty += item.quantity
                packingProcess.delivered = 'NA'
                packingProcess.produced += totalProduced
                packingProcess.available =
                    this.calculateReleasedQty(item) - totalProduced

                packedProcess.qty += item.quantity
                packedProcess.delivered = 'NA'
                packedProcess.produced += item.packedQty
                packedProcess.available =
                    totalProduced - (packedProcess.produced || 0)

                //quality process
                qualityProcess.qty += item.quantity
                qualityProcess.delivered = 'NA'
                qualityProcess.produced =
                    this.calculateRejectedQty(item) +
                    this.calculateReleasedQty(item)
                qualityProcess.available = this.calculateQualityPending(item)

                productionInfo.push(qualityProcess)
                productionInfo.push(packingProcess)
                productionInfo.push(packedProcess)
            }
            return productionInfo || []
        },
        async setOrderData() {
            // set partNumber
            this.partNumbers = await API.getPartNumbers(
                this.workOrder.projectId
            )
            // set processes and finishAndUCCode
            const {
                data: { settings },
            } = await API.getSettings()
            this.settingProcesses = settings.find(s => s.name == 'Processes')
            this.settingFinish = settings.find(s => s.name == 'FinishAndUCCode')
            if (this.workOrder.items && this.workOrder.items.length > 0) {
                for (let item of this.workOrder.items) {
                    const partNumber = this.partNumbers.find(
                        pn => pn.id === item.partNumberId
                    )
                    if (partNumber) {
                        item.partNumber = partNumber.code
                    }
                    if (this.settingProcesses.id) {
                        if (item.processes?.length > 0) {
                            item.dataProcesses = this.settingProcesses.processes.filter(
                                process => item.processes.includes(process.id)
                            )
                        }
                    }
                    if (this.settingFinish.id) {
                        if (item.finishAndUCCode) {
                            item.dataFinishAndUCCode = this.settingFinish.finishes.find(
                                e => e.id == item.finishAndUCCode
                            )
                        }
                    }
                    item.productionInfo = this.calculateFinished(item)
                }
            }
            this.workOrder.items = [
                ...(this.workOrder.items != undefined
                    ? this.workOrder.items
                    : []),
            ]
        },
        async setUpdatedData() {
            if (this.workOrder.items && this.workOrder.items.length > 0) {
                for (let item of this.workOrder.items) {
                    const partNumber = this.partNumbers.find(
                        pn => pn.id === item.partNumberId
                    )
                    if (partNumber) {
                        item.partNumber = partNumber.code
                    }
                    if (this.settingProcesses.id) {
                        if (item.processes?.length > 0) {
                            item.dataProcesses = this.settingProcesses.processes.filter(
                                process => item.processes.includes(process.id)
                            )
                        }
                    }
                    if (this.settingFinish.id) {
                        if (item.finishAndUCCode) {
                            item.dataFinishAndUCCode = this.settingFinish.finishes.find(
                                e => e.id == item.finishAndUCCode
                            )
                        }
                    }
                    item.productionInfo = this.calculateFinished(item)
                }
            }
            this.workOrder.items = [
                ...(this.workOrder.items != undefined
                    ? this.workOrder.items
                    : []),
            ]
        },
        allItemsCompleted() {
            let itemsCompleted = true
            for (let item of this.workOrder.items) {
                if (item.processes) {
                    for (let process of item.processes) {
                        let totalProduced = 0
                        if (item.production) {
                            let production = item.production.filter(
                                register => register.process.id === process
                            )
                            totalProduced = production.reduce(
                                (total, register) => total + register.qty,
                                0
                            )
                        }
                        if (totalProduced < item.quantity) {
                            itemsCompleted = false
                            break
                        }
                    }
                }
                if (!itemsCompleted) {
                    break
                }
            }
            return itemsCompleted
        },
        openMultipleProcesses() {
            this.multipleProcessDialog = true
        },
        closeMultipleProcesses() {
            this.multipleProcessDialog = false
        },
        openMultipleRegister() {
            this.multipleRegisterDialog = true
        },
        closeMultipleRegister() {
            this.multipleRegisterDialog = false
        },
        openMultipleSend() {
            this.multipleSendDialog = true
        },
        closeMultipleSend() {
            this.multipleSendDialog = false
        },
        disableCloseProcess() {
            //TODO: uncomment in update
            return false
            // return this.workOrder.items.some(
            //     item => item.quantity > (item.packedQty || 0)
            // )
        },
        registerQuality(item) {
            this.selectedItem = _.cloneDeep(item)
            this.qualityDialog = true
        },
        closeQualityProcess() {
            this.selectedItem = _.cloneDeep({})
            this.qualityDialog = false
        },
        openMultipleQuality() {
            this.multipleQualityDialog = true
        },
        closeMultipleQualityProcess() {
            this.multipleQualityDialog = false
        },
        calculateQualityPending(item) {
            try {
                let pendingQty = 0
                const lastProductionProcess =
                    item.processes[item.processes.length - 1]
                if (item.production) {
                    const production = item.production.filter(
                        entry => entry.process.id == lastProductionProcess
                    )
                    if (production.length > 0) {
                        pendingQty = production.reduce(
                            (accumulator, entry) => accumulator + entry.qty,
                            0
                        )
                    }
                }
                if (item.quality) {
                    const rejected = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.rejectQty || 0),
                        0
                    )
                    const released = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.releaseQty || 0),
                        0
                    )
                    pendingQty -= Number(released || 0) + Number(rejected || 0)
                }
                return pendingQty
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateReleasedQty(item) {
            try {
                let released = 0
                if (item.quality) {
                    const value = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.releaseQty || 0),
                        0
                    )
                    released += Number(value || 0)
                }
                return released
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateReprocessQty(item) {
            try {
                let reprocess = 0
                if (item.quality) {
                    const value = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.reprocessQty || 0),
                        0
                    )
                    reprocess += Number(value || 0)
                }
                return reprocess
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        calculateRejectedQty(item) {
            try {
                let rejected = 0
                if (item.quality) {
                    const value = item.quality.reduce(
                        (accumulator, entry) =>
                            accumulator + (entry.rejectQty || 0),
                        0
                    )
                    rejected += Number(value || 0)
                }
                return rejected
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openProductionLog(item) {
            this.selectedItem = _.cloneDeep(item)
            this.viewType = 'production'
            this.itemLogDialog = true
        },
        openDeliveryLog(item) {
            this.selectedItem = _.cloneDeep(item)
            this.viewType = 'delivery'
            this.itemLogDialog = true
        },
        openQualityLog(item) {
            this.selectedItem = _.cloneDeep(item)
            this.viewType = 'quality'
            this.itemLogDialog = true
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
</style>
